// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-getcinder-js": () => import("./../src/pages/getcinder.js" /* webpackChunkName: "component---src-pages-getcinder-js" */),
  "component---src-pages-getstarted-index-js": () => import("./../src/pages/getstarted/index.js" /* webpackChunkName: "component---src-pages-getstarted-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-termsofservice-js": () => import("./../src/pages/termsofservice.js" /* webpackChunkName: "component---src-pages-termsofservice-js" */)
}

